import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { RetrievalMode } from "../../types/retrieval-mode";
import { AbstractCalculator } from "../abstract-calculator";

export class OutgoingStockPrognosisCalculator extends AbstractCalculator {
    // Weekly
    // ------
    // Sets Outgoing Stock Prognosis Weekly

    // Dependencies
    // ------------
    // Stock Gross
    // Stock Forecast
    // Shrink% LY 

    // Calculates
    // ----------
    // Outgoing Stock Prognosis Weekly (calculated)

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem) {
        let value = 0;
        let retrievalMode: RetrievalMode = this.calculatorDriver.getRetrievalMode();

        if (retrievalMode == RetrievalMode.Bought || retrievalMode == RetrievalMode.Initial) {

            if (this.calculatorDriver.getViewWeekWithYear() > currentWeekItem[CalculationDataItemType.WeekName]) {
                value = currentWeekItem[CalculationDataItemType.StockGross]> 0 ?  currentWeekItem[CalculationDataItemType.StockGross] : 0 ;
            }
            else{
                value = currentWeekItem[CalculationDataItemType.StockForecastWeekly]
            }
        }
        else if (retrievalMode == RetrievalMode.Plan) {

            value = currentWeekItem[CalculationDataItemType.StockForecastWeekly];          
        }

        let outgoingStockPrognosisWeekly = value * currentWeekItem[CalculationDataItemType.ShrinkPercentLy]/100;

        currentWeekItem[CalculationDataItemType.OutgoingStockPrognosisWeekly] = outgoingStockPrognosisWeekly;
    }

}