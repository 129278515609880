import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ChannelType } from "../../types/channel-type";
import { Constants } from "../../types/constants";
import { RetrievalMode } from "../../types/retrieval-mode";
import { AbstractCalculator } from "../abstract-calculator";

export class StockForecastCalculator extends AbstractCalculator {

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem) {


        let value = 0;
        let retrievalMode: RetrievalMode = this.calculatorDriver.getRetrievalMode();
        let utils = this.calculatorDriver.getUtilsService();

        let startWeekForCurrentSeason = utils.getStartWeekForSeason(parseInt(this.calculatorDriver.getSeasonInfo().seasonCodeNames[0]));

        let channelType: ChannelType = this.calculatorDriver.getUserConfig().planningViewOptions.channel;

        if (this.calculatorDriver.getViewWeekWithYear() > currentWeekItem[CalculationDataItemType.WeekName]) {
            value = currentWeekItem[CalculationDataItemType.StockGross] > 0 ?  currentWeekItem[CalculationDataItemType.StockGross] : 0 ;
        }
        else {
            let buying = 0;
            let addRemoveTSek = (currentWeekItem[CalculationDataItemType.AddRemoveMSek] * Constants.TSEKTOMSEK);

            if (retrievalMode == RetrievalMode.Plan) {
                buying = currentWeekItem[CalculationDataItemType.PurchasePlanFixed] + addRemoveTSek;
            }

            else if (retrievalMode == RetrievalMode.Bought) {
                buying = currentWeekItem[CalculationDataItemType.BoughtGross] + addRemoveTSek;
            }

            else if (retrievalMode == RetrievalMode.Initial) {
                let currentPeriodOrderedGrossGroundSum = 0;

                currentPeriodOrderedGrossGroundSum += utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.OrderedGrossGround);


                buying = (currentPeriodOrderedGrossGroundSum / 4) + addRemoveTSek;
            }

            if (previousWeekItem) {
                value += previousWeekItem[CalculationDataItemType.StockForecastWeekly];
            }
            value += buying + (currentWeekItem[CalculationDataItemType.AddMovesMSek] * Constants.TSEKTOMSEK) - currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly];


            if (channelType == ChannelType.Online) {

                if (currentWeekItem[CalculationDataItemType.WeekName] < utils.shiftWeeks(startWeekForCurrentSeason, -8) && currentWeekItem[CalculationDataItemType.StockGross] == 0) {
                    value = 0;
                }
                else {
                    value += currentWeekItem[CalculationDataItemType.PlannedReturn];
                }
            }
        }

        currentWeekItem[CalculationDataItemType.StockForecastWeekly] = value;

    }
}